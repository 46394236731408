import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { User } from './pages/users/types'
import { useToast } from 'vuestic-ui'

const baseURL = import.meta.env.VITE_API_BASE_URL || `https://irebox-web.manosens.com/api`
const token = localStorage.getItem('token')
const { init } = useToast()

const axiosInstance = axios.create({
  baseURL,
})

if (token && token !== 'undefined') {
  const decodedToken = jwtDecode(token)
  const currentTime = Math.floor(Date.now() / 1000)

  if (decodedToken.exp != null && decodedToken.exp < currentTime) {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
  } else {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }
}

// export const verify_token = async () => {
//   // used for verifying user NOT IN USE
//   axiosInstance.defaults.withCredentials = true
//   axiosInstance.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
//   const result = await axiosInstance.post('/auth/verify', {
//     "token": localStorage.getItem('token')
//   })
//   console.log(result.status)
//   return result.status === 200;
// }

export const get_devices = async () => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  return await axiosInstance.get('/user-devices')
}

export const post_login = async (credentials: { email: string; password: string }) => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
  return await axiosInstance.post('/auth/jwt/login', {
    username: credentials.email,
    password: credentials.password,
  })
}

export const logout = async () => {
  if (!localStorage.getItem('token')) return
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  return await axiosInstance.post('/auth/jwt/logout')
}

export const get_user = async () => {
  if (!localStorage.getItem('token')) return { status: 401 }
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  return await axiosInstance.get('/users/me')
}

export const get_users = async () => {
  if (!localStorage.getItem('token')) return { status: 401 }
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  return await axiosInstance.get('/users-all')
}

export const register_user = async (credentials: { email: string; password: string }) => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.common['Content-Type'] = 'application/json'
  return await axiosInstance.post('/auth/register', {
    email: credentials.email,
    password: credentials.password,
    ireboxes: [],
  })
}

export const updateUser = async (user: User) => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.common['Content-Type'] = 'application/json'
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  try {
    return await axiosInstance.patch(`/users/${user.id}`, {
      email: user.email,
      is_active: user.is_active,
      is_superuser: user.is_superuser,
      is_verified: user.is_verified,
      ireboxes: user.ireboxes,
    })
  } catch (e: any) {
    init({ message: 'Error modifying user. Response:' + e.status, color: 'danger' })
  }
}

export const addUserInternal = async (user: User) => {
  const response = await register_user({ email: user.email, password: 'manosens123' })
  if (response.status === 201) {
    init({ message: 'User created successfully', color: 'success' })
  } else {
    init({ message: 'Error creating user. Response:' + response.status, color: 'danger' })
  }
  user.id = response.data.id
  await updateUser(user)
  return response
}

export const removeUser = async (user: User) => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  try {
    return await axiosInstance.delete(`/users/${user.id}`)
  } catch (e: any) {
    init({ message: 'Error deleting user. Response:' + e.status, color: 'danger' })
  }
}

export default axiosInstance
