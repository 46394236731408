import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

import { useAuthStore } from '../stores/auth'
import { get_devices } from '../axios'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'home' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'home' },
    meta: { requiresAuth: true },
    children: [
      {
        name: 'home',
        path: 'home',
        component: () => import('../pages/home/Home.vue'),
        props: true,
      },
      {
        name: 'dashboard',
        path: 'dashboard/:uuid?',
        beforeEnter: (to, from, next) => {
          beforeEnterRoutes(to, from, next, 'dashboard')
        },
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
        props: true,
      },
      {
        name: 'no_dashboard',
        path: 'no_dashboard',
        component: () => import('../pages/admin/dashboard/NoDashboard.vue'),
      },
      {
        name: 'faults',
        path: 'faults/:uuid?',
        component: () => import('../pages/faults/Faults.vue'),
        meta: { requiresSuper: true },
        props: true,
        beforeEnter: (to, from, next) => {
          beforeEnterRoutes(to, from, next, 'faults')
        },
      },
      {
        name: 'battery',
        path: 'battery/:uuid?',
        beforeEnter: (to, from, next) => {
          beforeEnterRoutes(to, from, next, 'battery')
        },
        component: () => import('../pages/bms/BmsDashboard.vue'),
        meta: { requiresSuper: true },
        props: true,
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users/UsersPage.vue'),
        meta: { requiresSuper: true },
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const beforeEnterRoutes = (to: any, from: any, next: any, name: string) => {
  if (to.params.uuid === 'default' || to.params.uuid === undefined || to.params.uuid == null || to.params.uuid == '') {
    get_devices().then((response) => {
      if (response.data.length > 0) {
        next({ name: name, params: { uuid: response.data[0] }, path: '/' + name + '/' + response.data[0] })
      } else {
        console.log('No devices found')
        next({ name: 'no_dashboard' })
      }
    })
  } else {
    next()
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()
  await authStore.initializeAuth()
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (authStore.isAuthenticated()) {
      if (to.matched.some((record) => record.meta.requiresSuper)) {
        if (authStore.isSuperUser()) {
          next()
          return
        } else {
          next({ path: '/404' })
          return
        }
      }
      next()
      return
    } else {
      next({ path: '/auth' })
      return
    }
  } else {
    next()
    return
  }
})

export default router
