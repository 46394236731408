<template>
  <VaSidebar v-model="writableVisible" :width="sidebarWidth" :color="color" minimized-width="0">
    <VaSidebarItem hover-color="#FFFFFF" hover-opacity="0">
      <VaSidebarItemContent class="overflow-hidden flex flex-direction-column">
        <VaButton @click="logout">
          {{ t('custom.logout') }}
        </VaButton>
      </VaSidebarItemContent>
    </VaSidebarItem>
    <VaAccordion v-if="!loading" v-model="value" multiple>
      <VaCollapse v-for="(route, index) in filterNavigationRoutes(navigationRoutes.routes)" :key="index">
        <template #header="{ value: isCollapsed }">
          <VaSidebarItem
            :to="route.children ? undefined : { name: route.name }"
            :active="routeHasActiveChild(route)"
            :active-color="activeColor"
            :text-color="textColor(route)"
            :aria-label="`${route.children ? 'Open category ' : 'Visit'} ${t(route.displayName)}`"
            role="button"
            hover-opacity="0.10"
          >
            <VaSidebarItemContent class="py-3 pr-2 pl-4">
              <VaIcon
                v-if="route.meta.icon"
                aria-hidden="true"
                :name="route.meta.icon"
                size="20px"
                :color="iconColor(route)"
              />
              <VaSidebarItemTitle class="flex justify-between items-center leading-5 font-semibold">
                {{ t(route.displayName) }}
                <VaIcon
                  v-if="route.children?.length && route.children.length > 0"
                  :name="arrowDirection(isCollapsed)"
                  size="20px"
                />
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </VaSidebarItem>
        </template>
        <template #body>
          <div v-for="(childRoute, index2) in route.children" :key="index2">
            <VaSidebarItem
              :to="{ name: childRoute.name, params: { uuid: childRoute.displayName } }"
              :active="isActiveChildRoute(childRoute)"
              :active-color="activeColor"
              :text-color="textColor(childRoute)"
              :aria-label="`Visit ${t(route.displayName)}`"
              hover-opacity="0.10"
            >
              <VaSidebarItemContent class="py-3 pr-2 pl-5">
                <VaIcon class="material-icons">battery_charging_full</VaIcon>
                <VaSidebarItemTitle class="leading-3 font-semibold text-[0.65rem]">
                  {{ childRoute.displayName }}
                </VaSidebarItemTitle>
              </VaSidebarItemContent>
            </VaSidebarItem>
          </div>
        </template>
      </VaCollapse>
    </VaAccordion>
    <VaSpacer></VaSpacer>
    <VaSidebarItem hover-color="#FFFFFF" hover-opacity="0">
      <VaSidebarItemContent class="overflow-hidden flex flex-direction-column">
        <VaSidebarItemTitle class="leading-5 font-semibold">
          {{ t('custom.language') }}
        </VaSidebarItemTitle>
        <VaSwitch
          v-model="language_value"
          color="#ecf0f1"
          off-color="#ecf0f1"
          style="--va-switch-checker-background-color: #252723; --va-switch-checker-active-background-color: #252723"
          @change="changeLanguage(language_value ? 'cs' : 'gb')"
        >
          <template #innerLabel>
            <div class="va-text-center">
              <VaListLabel class="va-text-sm">
                {{ language_value ? 'CZ' : 'EN' }}
              </VaListLabel>
            </div>
          </template>
        </VaSwitch>
      </VaSidebarItemContent>
    </VaSidebarItem>
  </VaSidebar>
</template>
<script lang="ts">
import { defineComponent, watch, ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'

import { useI18n } from 'vue-i18n'
import { useColors } from 'vuestic-ui'

import navigationRoutes, { type INavigationRoute } from './NavigationRoutes'
import { useAuthStore } from '../../stores/auth'
import { get_devices } from '../../axios'

export default defineComponent({
  name: 'Sidebar',
  props: {
    visible: { type: Boolean, default: true },
    mobile: { type: Boolean, default: false },
  },
  emits: ['update:visible'],

  setup: (props, { emit }) => {
    interface IRouteChildren {
      name: string
      routeChildren: INavigationRoute[]
    }

    const { getColor, colorToRgba } = useColors()
    const route = useRoute()
    const { t, locale } = useI18n()
    const authStore = useAuthStore()
    const routeData = ref<INavigationRoute[]>([])
    const routeChildren = ref<IRouteChildren[]>([])
    const loading = ref(true)
    let read_children = false
    const mounted = ref(false)

    // Add routes here
    const routes = ['dashboard', 'faults', 'battery']

    onMounted(() => {
      mounted.value = true
    })
    onBeforeUnmount(() => {
      mounted.value = false
      routeData.value = []
      routeChildren.value = []
    })

    watch(
      () => mounted.value,
      async () => {
        const devices = await get_devices()
        devices.data.forEach((device: string) => {
          routes.forEach((routeName: string) => {
            routeChildren.value.push({
              name: routeName,
              routeChildren: [
                {
                  name: routeName,
                  path: `${routeName}:/${device}`,
                  props: true,
                  params: { uuid: device },
                  displayName: device,
                  meta: {
                    icon: 'default-icon',
                    requiresSuperuser: false,
                  },
                },
              ],
            })
          })
        })
        routeData.value = filterNavigationRoutes(navigationRoutes.routes)
        loading.value = false
      },
    )

    const filterNavigationRoutes = (navigationRoutes: INavigationRoute[]) => {
      return navigationRoutes.filter((route) => {
        // use routes to loop through all the routes
        routes.forEach((routeName: string) => {
          if (route.name === routeName && !read_children && routeChildren.value.length === 0) {
            read_children = true
            route.children = []
          }
          if (route.name === routeName) {
            read_children = true
            const newChildren: IRouteChildren[] = routeChildren.value.filter((device) => {
              return device.name === routeName
            })

            if (newChildren.length === 0) {
              return route
            }

            route.children = [...(route.children ?? []), ...newChildren[0].routeChildren]
            route.children = route.children.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i)

            return route
          }
        })
        return !route.meta.requiresSuperuser || authStore.isSuperUser()
      })
    }

    const logout = () => {
      authStore.logout()
    }

    const changeLanguage = (newLocale: string) => {
      locale.value = newLocale
      localStorage.setItem('locale', newLocale)
    }

    const value = ref<boolean[]>([])
    const language_value = ref(locale.value === 'cs')

    const writableVisible = computed({
      get: () => props.visible,
      set: (v: boolean) => emit('update:visible', v),
    })

    const isActiveChildRoute = (child: INavigationRoute) => route.name === child.name

    const routeHasActiveChild = (section: INavigationRoute) => {
      if (!section.children) {
        return route.path.endsWith(`${section.name}`)
      }

      return section.children.some(({ name }) => route.path.endsWith(`${name}`))
    }

    const setActiveExpand = () =>
      (value.value = navigationRoutes.routes.map((route: INavigationRoute) => routeHasActiveChild(route)))

    const sidebarWidth = computed(() => (props.mobile ? '100vw' : '280px'))
    const color = computed(() => getColor('background-secondary'))
    const activeColor = computed(() => colorToRgba(getColor('focus'), 0.1))

    const iconColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'primary' : 'secondary')
    const textColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'primary' : 'textPrimary')
    const arrowDirection = (state: boolean) => (state ? 'va-arrow-up' : 'va-arrow-down')

    watch(() => route.fullPath, setActiveExpand, { immediate: true })

    return {
      writableVisible,
      sidebarWidth,
      value,
      color,
      activeColor,
      navigationRoutes,
      routeHasActiveChild,
      isActiveChildRoute,
      t,
      iconColor,
      textColor,
      arrowDirection,
      changeLanguage,
      language_value,
      logout,
      filterNavigationRoutes,
      loading,
    }
  },
})
</script>
